import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import './App.scss'

import Header from './containers/Layout/Header/Header'
import Footer from './containers/Layout/Footer/Footer'
import Dashboard from './containers/Dashboard/Dashboard'
import NotFound from './containers/NotFound/NotFound'

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <div className='app'>
        <Header />
        <Switch>
          <Route path='/' exact component={Dashboard} />
          <Route path='/country/:id' component={Dashboard} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App
