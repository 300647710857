import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://disease.sh/v3/covid-19/'
})

instance.defaults.headers.common['Cache-Control'] = 'no-cache'

instance.interceptors.request.use(
  (request) => {
    // console.log('[Request]', request)
    return request
  },
  (error) => {
    // console.log('[Req Error]', error)
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    // console.log('[Response]', response.data)
    return response
  },
  (error) => {
    // console.log('[Res Error]', error)
    return Promise.reject(error)
  }
)

export default instance
