import React, { Component } from 'react'

import classes from './Box.module.scss'

export class Box extends Component {
  render() {
    let todayNumber = null
    let perMillion = null

    if (this.props.todayNumber) {
      todayNumber = <p className={classes['today']}>Today: +{this.props.todayNumber}</p>
    }
    if (this.props.perMillion) {
      perMillion = <p className={classes['per-mil']}>{this.props.perMillion} / 1M pop.</p>
    }

    return (
      <div className={[classes['box'], classes[this.props.class]].join(' ')} tabIndex='0'>
        <p className={classes['title']}>{this.props.title}:</p>
        <p className={classes['total-number']}>{this.props.number}</p>

        {todayNumber}
        {perMillion}
      </div>
    )
  }
}

export default Box
