import React, { Component, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { fetchData } from '../../store/api/data'

import Lazyload from 'react-lazyload'

// import MapDetail from '../../components/Map/MapDetail'
// import Map from '../../components/Map/Map'
import Total from '../../components/Total/Total'
import Loader from '../../components/UI/Loader/Loader'

import classes from './Dashboard.module.scss'

const TotalGraph = lazy(() => import('../../components/Graphs/Totals/TotalGraph'))
const DailyGraph = lazy(() => import('../../components/Graphs/Daily/DailyGraph'))
const CasesGraph = lazy(() => import('../../components/Graphs/Half/CasesGraph'))
const DeathsGraph = lazy(() => import('../../components/Graphs/Half/DeathsGraph'))
const Source = lazy(() => import('../../components/Source/Source'))

const renderLoader = <Loader />

class Dashboard extends Component {
  componentDidMount() {
    const id = this.props.match.params.id ? this.props.match.params.id.toUpperCase() : ''
    this.props.fetchData(id)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      const id = this.props.match.params.id ? this.props.match.params.id.toUpperCase() : ''
      this.props.fetchData(id)
    }
  }

  render() {
    // let map = null

    // if (process.env.NODE_ENV !== 'development') {
    //   map = <Map map={{ ...this.props.map }} data={{ ...this.props.data }} />
    // } else {
    //   map = <MapDetail map={{ ...this.props.map }} data={{ ...this.props.data }} />
    // }

    let totalGraph = this.props.match.params.id ? (
      <Suspense fallback={renderLoader}>
        <TotalGraph {...this.props.graph} />
      </Suspense>
    ) : (
      <Suspense fallback={renderLoader}>
        <TotalGraph {...this.props.world} />
      </Suspense>
    )

    let dailyGraph = this.props.match.params.id ? (
      <Suspense fallback={renderLoader}>
        <DailyGraph {...this.props.graph} />
      </Suspense>
    ) : (
      <Suspense fallback={renderLoader}>
        <DailyGraph {...this.props.world} />
      </Suspense>
    )

    let halfGraph = this.props.match.params.id ? (
      <div className={classes['half-graphs']}>
        <Suspense fallback={renderLoader}>
          <CasesGraph {...this.props.graph} />
          <DeathsGraph {...this.props.graph} />
        </Suspense>
      </div>
    ) : null

    return (
      <div className={classes['dashboard']}>
        <section className={classes['total-container']}>
          <Total {...this.props.data} />

          {/* {map} */}
        </section>

        <section className={classes['graph-container']}>
          <Lazyload offset={100}>{totalGraph}</Lazyload>
          <Lazyload offset={100}>{dailyGraph}</Lazyload>
          <Lazyload offset={100}>{halfGraph}</Lazyload>
        </section>

        <section className={classes['sources']}>
          <Suspense fallback={renderLoader}>
            <Source />
          </Suspense>
        </section>
      </div>
    )
  }
}

const stateToProps = (state) => ({
  data: state.total.data,
  loading: state.total.loading,
  // map: state.map.data,
  graph: state.graph.data,
  world: state.graph.world
})

export default connect(stateToProps, { fetchData })(Dashboard)
