import * as actionTypes from '../types/actionTypes'

export const graphData = (data) => {
  return {
    type: actionTypes.GRAPH_DATA,
    payload: data
  }
}

export const worldData = (data) => {
  return {
    type: actionTypes.WORLD_DATA,
    payload: data
  }
}
