// Adds commas to thousands number
export const numberFormat = (value) =>
  new Intl.NumberFormat('en-GB', {
    style: 'decimal'
  }).format(value)

// Adds day and month in numeric form
export const formatGraphDate = (string) => {
  var options = { day: 'numeric', month: 'numeric' }
  return new Date(string).toLocaleDateString([], options)
}

// Full date numeric and words
export const formatDate = (string) => {
  var options = { day: 'numeric', month: 'long', year: 'numeric' }
  return new Date(string).toLocaleDateString([], options)
}

// Full date with time
export const formatDateTime = (string) => {
  var options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' }
  return new Date(string).toLocaleDateString([], options)
}

// Number short hand
export const DataFormater = (number) => {
  if (number > 999999999) {
    return (number / 1000000000).toString() + 'B'
  } else if (number > 999999) {
    return (number / 1000000).toString() + 'M'
  } else if (number > 999) {
    return (number / 1000).toString() + 'K'
  } else {
    return number.toString()
  }
}
