// Data load
export const DATA_LOADING = 'DATA_LOADING'
export const DATA_ERROR = 'DATA_ERROR'
export const DATA_SUCCESS = 'DATA_SUCCESS'

// Map
export const MAP_DATA = 'MAP_DATA'

// Graph
export const GRAPH_DATA = 'GRAPH_DATA'
export const WORLD_DATA = 'WORLD_DATA'
export const GRAPH_MULTI = 'GRAPH_MULTI'

// Dropdown
export const DROPDOWN_VALUES = 'DROPDOWN_VALUES'
