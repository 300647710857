import * as actionTypes from '../types/actionTypes'

const initialState = {
  data: []
}

const reducer = (state = initialState, action) => {
  // console.log('action type => ', action.type)
  switch (action.type) {
    case actionTypes.DROPDOWN_VALUES:
      return {
        ...state,
        data: action.payload
      }
    default:
      return state
  }
}

export default reducer
