import * as actionTypes from '../types/actionTypes'

export const dataLoading = () => {
  return {
    type: actionTypes.DATA_LOADING
  }
}

export const dataSuccess = (data) => {
  return {
    type: actionTypes.DATA_SUCCESS,
    payload: data
  }
}

export const dataError = (error) => {
  return {
    type: actionTypes.DATA_ERROR,
    payload: error
  }
}
