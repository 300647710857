import React from 'react'

import { connect } from 'react-redux'
import { fetchData } from '../../../store/api/data'

import classes from './Header.module.scss'
import logo from '../../../assets/images/covid-19-virus.png'
import logoWP from '../../../assets/images/covid-19-virus.webp'
import Dropdown from '../../../components/UI/Dropdown/Dropdown'

const Header = (props) => (
  <header>
    <div className={classes['container']}>
      <div className={classes['project-title']}>
        <a href='/'>
          <picture>
            <source srcSet={logoWP} type='image/webp' />
            <img src={logo} alt='COVID-19 virus (possibly) created by CDC/ Alissa Eckert, MS; Dan Higgins, MAMS' />
          </picture>
          <h1>
            <span>COVID-19</span> tracker
          </h1>
        </a>
      </div>
    </div>
    <div className={classes['search']}>
      <Dropdown countries={{ ...props.drop }} />
    </div>
  </header>
)

const stateToProps = (state) => ({
  drop: state.drop.data
})

export default connect(stateToProps, { fetchData })(Header)
