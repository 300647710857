import * as actionTypes from '../types/actionTypes'

const initialState = {
  data: [],
  loading: false,
  error: null
}

const reducer = (state = initialState, action) => {
  // console.log('action type => ', action.type)
  switch (action.type) {
    case actionTypes.DATA_LOADING:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actionTypes.DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      }
    case actionTypes.DATA_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      }
    default:
      return state
  }
}

export default reducer
