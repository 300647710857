import React, { Component } from 'react'

import classes from './Loader.module.scss'

export default class Loader extends Component {
  render() {
    return (
      <div className={classes['loading']}>
        <div className={classes['sk-folding-cube']}>
          <div className={[classes['sk-cube1'], classes['sk-cube']].join(' ')}></div>
          <div className={[classes['sk-cube2'], classes['sk-cube']].join(' ')}></div>
          <div className={[classes['sk-cube4'], classes['sk-cube']].join(' ')}></div>
          <div className={[classes['sk-cube3'], classes['sk-cube']].join(' ')}></div>
        </div>

        <p className={classes['loading-text']}>Loading data...</p>
      </div>
    )
  }
}
