import React, { Component } from 'react'
import { numberFormat, formatDateTime } from '../../store/utility'

import classes from './Total.module.scss'
import Box from '../UI/Box/Box'

class Total extends Component {
  render() {
    let countryName = this.props.country ? this.props.country : 'Global'

    let continent = null

    if (this.props.continent) {
      continent = (
        <p className={classes['continent']}>
          Continent: <strong>{this.props.continent}</strong>
        </p>
      )
    }

    return (
      <div className={classes['totals']}>
        <div className={classes['country-stats']}>
          <h2>
            {countryName} <span>statistics</span>
          </h2>

          {continent}

          <p className={classes['pop']}>
            Population: <strong>{numberFormat(this.props.population || 0)}</strong>
          </p>

          <p className={classes['updated']}>
            <span>Last updated: {formatDateTime(this.props.updated)}</span>
          </p>
        </div>

        <div className={classes['boxes']}>
          <Box
            title={'Total cases'}
            class={'confirmed'}
            number={numberFormat(this.props.cases || 0)}
            perMillion={numberFormat(this.props.casesPerOneMillion || 0)}
            todayNumber={numberFormat(this.props.todayCases || 0)}
          />
          <Box
            title={'Total deaths'}
            class={'deaths'}
            number={numberFormat(this.props.deaths || 0)}
            perMillion={numberFormat(this.props.deathsPerOneMillion || 0)}
            todayNumber={numberFormat(this.props.todayDeaths || 0)}
          />
          <Box
            title={'Total recovered'}
            class={'recovered'}
            number={numberFormat(this.props.recovered || 0)}
            perMillion={numberFormat(this.props.recoveredPerOneMillion || 0)}
            todayNumber={numberFormat(this.props.todayRecovered || 0)}
          />
          <Box
            title={'Total tested'}
            class={'tested'}
            number={numberFormat(this.props.tests || 0)}
            perMillion={numberFormat(this.props.testsPerOneMillion || 0)}
            todayNumber={numberFormat(this.props.todayTests || 0)}
          />
          <Box
            title={'Active cases'}
            class={'active'}
            number={numberFormat(this.props.active || 0)}
            perMillion={numberFormat(this.props.activePerOneMillion || 0)}
            todayNumber={numberFormat(this.props.todayActive || 0)}
          />
          <Box
            title={'Critical cases'}
            class={'critical'}
            number={numberFormat(this.props.critical || 0)}
            perMillion={numberFormat(this.props.criticalPerOneMillion || 0)}
            todayNumber={numberFormat(this.props.todayCritical || 0)}
          />
        </div>
      </div>
    )
  }
}

export default Total
