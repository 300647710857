import React from 'react'
import classes from './Footer.module.scss'

const Footer = (props) => {
  const website = 'https://www.jonwelsh.me/'
  return (
    <footer>
      <div className={classes['container']}>
        <div className={classes['jw-logo']}>
          <span>A</span>{' '}
          <a
            href={website}
            data-umami-event='Profile click'
            data-umami-event-click={'JW profile site (' + website + ')'}
            className='mainws'
            target='_blank'
            rel='noopener noreferrer'>
            Jon Welsh
          </a>
          <span>project</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
