import * as actionCreators from '../types/index'
import axios from 'axios'

import disease from '../../api-disease'

export function fetchData(id) {
  // let diseaseJHU = '/jhucsse' // disease JHU (map data)
  let allCountries = '/countries' // disease JHU (country data)
  let diseaseHA = '/historical/all?lastdays=all' // disease JHU (world graph data)
  let diseaseData = id ? `/countries/${id}` : '/all' // disease
  let country = id ? `/historical/${id}?lastdays=all` : diseaseHA // historical data
  let diseaseAll = diseaseData // disease (world data)
  let trackerAll = country // tracker (country graph data)

  const requestDA = disease.get(diseaseAll)
  // const requestJHU = disease.get(diseaseJHU)
  const requestAC = disease.get(allCountries)
  const requestTA = disease.get(trackerAll)
  const requestHA = disease.get(diseaseHA)

  return function (dispatch) {
    dispatch(actionCreators.dataLoading())

    return axios
      .all([requestDA, requestAC, requestTA, requestHA])
      .then(
        axios.spread((...responses) => {
          let diseaseAll = responses[0].data
          let diseaseAC = responses[1].data
          let trackerAll = responses[2].data.timeline
          let diseaseHA = responses[3].data
          // let diseaseJHU = responses[4].data

          dispatch(actionCreators.dataSuccess(diseaseAll))
          // dispatch(actionCreators.mapData(diseaseJHU))
          dispatch(actionCreators.dropData(diseaseAC))
          dispatch(actionCreators.graphData(trackerAll))
          dispatch(actionCreators.worldData(diseaseHA))

          // console.log('UNUSED RESPONSES', diseaseHA)
        })
      )
      .catch((err) => dispatch(actionCreators.dataError(err)))
  }
}
