import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import createFilterOptions from 'react-select-fast-filter-options'

import './Dropdown.scss'

class Dropdown extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: null
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption })
    this.props.history.push(`/country/${selectedOption.value}`)
  }

  render() {
    const graphData = Object.values(this.props.countries).map((keyname, i) => keyname)
    const something = graphData || []

    let countries = something.map((item) => {
      let countryList = []
      let countryName = item.country
      let countryCode = item.countryInfo.iso2 !== null ? item.countryInfo.iso2.toLowerCase() : 0

      if (item.countryInfo.country !== null) {
        countryList = {
          ...{
            value: countryCode,
            label: countryName
          }
        }
      }

      return countryList
    })

    let data = countries.filter(function (el) {
      return el.value !== 0
    })

    const filterOptions = createFilterOptions({
      data
    })

    return (
      <Select
        filterOptions={filterOptions}
        options={data}
        placeholder={'Search for a country'}
        aria-label={'Search for a country'}
        className='select'
        classNamePrefix='select'
        menuPlacement='auto'
        value={this.props.match.params.id}
        onChange={this.handleChange}
      />
    )
  }
}

export default withRouter(Dropdown)
