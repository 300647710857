import React from 'react'
import { NavLink } from 'react-router-dom'

import classes from './NotFound.module.scss'

export default function NotFound() {
  return (
    <div className={classes['not-found']}>
      <h1>Uh oh</h1>
      <h2>Something's missing</h2>
      <p>
        Go{' '}
        <NavLink
          to={{
            pathname: '/'
          }}
          exact>
          home
        </NavLink>
      </p>
    </div>
  )
}
