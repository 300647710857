import React from 'react'
import ReactDOM from 'react-dom'
import pkg from '../package.json'

import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'

// ERROR REPORTING
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import TagManager from 'react-gtm-module'
import WebFont from 'webfontloader'

import totalReducer from './store/reducers/totalReducer'
import mapReducer from './store/reducers/mapReducer'
import dropReducer from './store/reducers/dropReducer'
import graphReducer from './store/reducers/graphReducer'

import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'

if (process.env.NODE_ENV !== 'development') {
  const tagManagerArgs = {
    gtmId: 'GTM-5BVH72S'
  }
  TagManager.initialize(tagManagerArgs)
}

WebFont.load({
  google: {
    families: ['Spartan:100,900&display=swap']
  }
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  release: 'covid-19-tracker@' + pkg.version,
  environment: process.env.NODE_ENV !== 'development' ? 'production' : 'development',
  integrations: [new Integrations.BrowserTracing()],
  normalizeDepth: 10,
  tracesSampleRate: process.env.NODE_ENV !== 'development' ? 0.25 : 1.0
})

const rootReducer = combineReducers({
  total: totalReducer,
  map: mapReducer,
  graph: graphReducer,
  drop: dropReducer
})

const logger = (store) => {
  return (next) => {
    return (action) => {
      // console.log('[Middleware] dispatching', action)
      const result = next(action)
      // console.log('[Middleware] next state', store.getState())

      return result
    }
  }
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk), sentryReduxEnhancer))

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={'An error has occured'}>
      <Provider store={store}>
        <App />
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
